





























































import useValidate from '@/use/validate'
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import Joi from 'joi'

export default defineComponent({
  setup(_, { root }) {
    const { tlds, validate } = useValidate()

    const form = ref<any>(null)

    const state = reactive({
      isLoading: false,
      valid: true,
      complete: false,
      success: false,
      error: false,
    })
  
    const model = reactive({
      NIP: '',
      REGON: '',
      KRS: '',

      name: '',
      address: '',
      postcode: '',
      city: '',
      phone: '',
      email: ''
    })

    const rules: { [key: string]: Array<(v: any) => boolean | string> } = {
      NIP: [v => !v || /^[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(v) || 'Niepoprawny format'],
      REGON: [v => !v || /^[0-9]{9}$/.test(v) || 'Niepoprawny format'],
      KRS: [v => !v || /^[0-9]{10}$/.test(v) || 'Niepoprawny format'],

      name: [v => !!v || 'Podaj nazwę firmy'],
      address: [v => !!v || 'Podaj ulicę i numer'],
      postcode: [v => !!v || 'Podaj kod pocztowy'],
      city: [v => !!v || 'Podaj miasto'],
      phone: [v => !!v || 'Podaj telefon'],
      email: [
        v => !!v || 'Podaj adres email',
        v => !!validate(v, Joi.string().email({ tlds: { allow: tlds.map(d => d.toLowerCase()) } })) || 'Podaj prawidłowy adres email'
      ]
    }

    const onSubmit = async () => {
      await form.value.validate()
      if (state.valid) state.complete = true
    }

    const goBack = () => {
      state.success = false
      state.complete = false
    }

    const addCompany = async () => {
      await form.value.validate()
      if (state.valid) {
        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

        state.isLoading = true
        
        axiosInstance
          .post('company', {
            NIP: model.NIP || undefined,
            Regon: model.REGON || undefined,
            KRS: model.KRS || undefined,

            name: model.name || undefined,
            address: model.address || undefined,
            postcode: model.postcode || undefined,
            city: model.city || undefined,
            phone: model.phone || undefined,
            email: model.email || undefined
          })
          .then(() => state.success = true)
          .catch(error => console.log(error))
          .finally(() => state.isLoading = false)

      } else {
        alert('Wprowadź prawidłowe dane')
      }
    }

    const reset = () => {
      model.NIP = '',
      model.REGON = '',
      model.KRS = '',

      model.name = '',
      model.address = '',
      model.postcode = '',
      model.city = '',
      model.phone = '',
      model.email = '',

      state.error = false
      state.valid = true
      state.isLoading = false
      state.complete = false
      state.success = false
    }

    return { form, state, model, rules, onSubmit, goBack, addCompany, reset }
  }
})
